import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fat } from '@fortawesome/pro-thin-svg-icons'
import SolutionBulletsHorizontal from '../components/solutionBulletsHorizontal'
import SolutionTextImageBelow from '../components/solutionTextImageBelow'
import SolutionBulletsImage from '../components/solutionBulletsImage'
import CardIcons from '../components/cardIcons.js'
import AltSection from '../components/columnAlternating'
import Card from '../components/card'

library.add(fat)

const About = ({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
        />
      </div>

      <main>
        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
                <div
                  className="font-w-400 text-xs-extra-large divlink text-opening-para"
                  dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara }}
                />

                {post.html != null && (
                  <div
                    className="mt-3 richtext divlink"
                    dangerouslySetInnerHTML={{ __html: post.html }}
                  />
                )}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>

        {post.frontmatter.bragroll && (
          <section className="bg-gray-light">
            <MDBAnimation reveal type="fadeIn" duration="1587ms" count={1}>
              <MDBContainer>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">
                  {post.frontmatter.bragroll.title}
                </h2>
                {post.frontmatter.bragroll.card && (
                  <MDBRow>
                    <>
                      {post.frontmatter.bragroll.card.map(cards => {
                        return (
                          <CardIcons
                            collg="4"
                            colmd="6"
                            title={cards.title}
                            subtitle={cards.subtitle}
                            image={cards.image.childImageSharp.gatsbyImageData}
                            alt={cards.alttext}
                          />
                        )
                      })}
                    </>
                  </MDBRow>
                )}
              </MDBContainer>
            </MDBAnimation>
          </section>
        )}    

        {post.frontmatter.helpfulresources && (
          <section className="bg-white-grey-gradient">
            <MDBAnimation reveal type="fadeIn" duration="1587ms">
              <MDBContainer>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                  {' '}
                  {post.frontmatter.helpfulresources.title}{' '}
                </h2>

                {post.frontmatter.helpfulresources.helpfulres && (
                  <MDBRow className="pt-5">
                    <>
                      {post.frontmatter.helpfulresources.helpfulres.map(
                        (helpfulres) => {
                          return (
                            <Card
                              collg="4"
                              colmd="6"
                              height="7.5rem"
                              title={helpfulres.title}
                              subtitle={helpfulres.subtitle}
                              description={helpfulres.description}
                              image={ helpfulres.image.childImageSharp.gatsbyImageData }
                              alttext={helpfulres.alttext}
                              placement={helpfulres.placement}
                              link={ helpfulres.link ? helpfulres.link : helpfulres.document.publicURL }
                              titleclass="title-small"
                              descriptionClass="text-card-small py-2"
                            />
                          )
                        }
                      )}
                    </>
                  </MDBRow>
                )}
              </MDBContainer>
            </MDBAnimation>
          </section>
        )}        

      </main>
    </Layout>
  )
}
export default About

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}   
      canonical={location.pathname} 
    />   
  )
}

export const query = graphql`
  query {   
    markdownRemark(frontmatter: { name: { eq: "About" } }) {
      frontmatter {
        meta {
          title
          description 
        }        
        name
        title
        subtitle
        openingpara
        alttext
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        bragroll {
          title
          card {
            title
            subtitle
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90) 
              }
            }
          }
        }  
        helpfulresources {
          title
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            document {
              publicURL
            }
          }
        }                              
      }
      html
    }
  }
`